import React, {useEffect, useState} from "react";
import {epdAstralService} from "../../../../services";
import {Button, Col, List, Modal, notification, Row} from "antd";
import CreateSignature from "../create-signature";
import Certificate from "../create-signature/certificate";
import {createDetachedSignature, createHash} from "crypto-pro";

export default function ModalJoinCompany({isShow, onCloseModal, id}) {

  const [isNeedUpdate, setIsNeedUpdate] = useState(true);
  const [points, setPoints] = useState([]);
  const [data, setData] = useState(null);
  const [dataDoc, setDataDoc] = useState(null);

  const [signature, setSignature] = useState('');
  const [signatureDataDoc, setSignatureDataDoc] = useState('');

  const [pointId, setPointID] = useState(null);
  const [guid, setGuid] = useState(null);
  const [nextTitle, setNextTitle] = useState(null);

  const [certificate, setCertificate] = useState(null);
  const [hash, setHash] = useState('');
  const [hashStatus, setHashStatus] = useState('Не вычислен');
  const [hashError, setHashError] = useState(null);
  const [signatureStatus, setSignatureStatus] = useState('Не создана');
  const [signatureError, setSignatureError] = useState(null);

  const setDefaultState = () => {
    setSignature('');
    setSignatureDataDoc('');
    setPointID(null);
    setCertificate(null);
    setHash(null);
    setData(null);
    setDataDoc(null);
    setGuid(null);
    setNextTitle(null);
  }

  useEffect(() => {
    if (isShow && isNeedUpdate) {
      epdAstralService.getPoints(id)
        .then((res) => {
          setPoints(res);
          setIsNeedUpdate(false);
        })
        .catch((err) => {
          notification.error({
            message: "Получение списка адресов",
            description: err,
          });
          setIsNeedUpdate(false);
        });
    }
  }, [isShow, isNeedUpdate]);

  useEffect(() => {
    if (signature !== '') {
      const blob = new Blob([signature], {type: "application/x-pkcs7-signature"});
      const file = new File([blob], `sign-${guid}.p7s`, {type: blob.type, lastModified: Date.now()});

      epdAstralService.getTitle({guid: guid, nextTitle: nextTitle, signedDoc: file})
        .then(resp => {
          setDataDoc(resp);
          setSignature('')
        })
        .catch((err) => {
          notification.error({
            message: "getTitle",
            description: err,
          });
        });
    }
  }, [signature]);

  useEffect(() => {
    if (signatureDataDoc !== '') {
      const blob = new Blob([signatureDataDoc], {type: "application/x-pkcs7-signature"});
      const file = new File([blob], `sign-${guid}.p7s`, {type: blob.type, lastModified: Date.now()});

      const docBlob = new Blob([dataDoc.data], {type: "application/x-pkcs7-signature"});
      const doc = new File([docBlob], dataDoc.name, {type: blob.type, lastModified: Date.now()});

      epdAstralService.getSendDocument({filename: dataDoc.name, doc: doc, signature: file, t: nextTitle})
        .then(res => {
          if (res.status === 'success') {
            notification.success({
              message: "Документ подписан",
              description: res.message,
            });
          }
          if (res.status === 'error') {
            notification.error({
              message: "Документ не подписан",
              description: res.message,
            });
          }
          setDefaultState();
        })
        .catch((err) => {
          notification.error({
            message: "Документ подписан",
            description: err,
          });
          setDefaultState();
        });
    }
  }, [signatureDataDoc]);

  const getXML = (guid, nextTitle, id) => {
    setPointID(id);
    setGuid(guid);
    setNextTitle(nextTitle);
    epdAstralService.getXML({guid: guid, nextTitle: nextTitle})
      .then(resp => {
        setData(resp);
      })
      .catch((err) => {
        notification.error({
          message: "Получение данных",
          description: err,
        });
      });
  }

  async function createSignature(message, setSignature) {
    let hash;

    setSignature('');
    setSignatureError(null);

    setHash('');
    setHashError(null);
    setHashStatus('Вычисляется...');

    try {
      hash = await createHash(message);

      setHash(hash);
      setHashStatus('Вычислен');
    } catch (error) {
      setHashError(error.message);
      setHashStatus('Не вычислен');

      return;
    }

    setSignatureStatus('Создается...');
    try {
      setSignature(await createDetachedSignature(certificate.thumbprint, hash));
      setSignatureStatus('Создана');
    } catch (error) {
      setSignatureError(error.message);
      setSignatureStatus('Не создана');
    }
  }

  const signatureStep1 = () => {
    if (certificate && data && dataDoc === null) {
      createSignature(data.data, setSignature);
    }
  }

  useEffect(() => {
    if (certificate && dataDoc) {
      createSignature(dataDoc.data, setSignatureDataDoc);
    }
  }, [data, dataDoc, certificate]);

  return (<Modal
      title={<div>Документы рейса</div>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        onCloseModal(null);
      }}
      forceRender={true}
      footer={<div></div>}
    >
      {points.length > 0 &&
        <List
          itemLayout="vertical"
          size="large"
          className="astral-user-companies-list"
          dataSource={points}
          locale={{emptyText: 'нет доступных точек'}}
          renderItem={item => {
            const buttons = [<>
              <Button
                type="primary"
                style={{marginLeft: "auto"}}
                onClick={() => getXML(item.guid, item.docflow.nextTitle, item.id)}
                disabled={item.id === pointId}
              >
                <span>Подписать</span>
              </Button>
            </>];
            return (
              <List.Item
                extra={buttons}
                style={{marginTop: "20px"}}
              >
                <List.Item.Meta
                  description={
                    <Row>
                      <Col>{item.docflow.typeLabel}</Col>
                    </Row>}
                  title={item.point.address}
                />
                {
                  item.id === pointId &&
                  <>
                    <Certificate onChange={setCertificate}/>
                    <Row>
                      <Col>
                        <Button
                          type="primary"
                          style={{marginLeft: "auto", width: "100%"}}
                          onClick={() => signatureStep1()}
                          disabled={certificate === null}
                        >
                          <span>Сертификат {certificate === null ? 'не выбран.' : 'выбран. Подписать'}</span>
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
              </List.Item>
            )
          }}
        />
      }
    </Modal>
  )
}
