import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Col, Icon, List, notification, Row, Switch} from "antd";
import {epdAstralService, userServices} from "../../../../services";
import ModalRegisterApiUser from "../../../modals/epd-astral/register-api-user";
import moment from "moment";
import {useCryptoPro} from "../../../../hooks/use-crypto-pro";
import ModalProfileApiUser from "../../../modals/epd-astral/profile-api-user";
import ModalLoginApiCompany from "../../../modals/epd-astral/login-api-company";
import ModalCompanyUsersList from "../../../modals/epd-astral/company-users-list";
import {USER_STATUSES, USER_STATUSES_TITLE} from "../../../../helpers/epd_astral.helper";



export default function EpdAstral() {
  const [isModalRegisterApiUserShow, setIsModalRegisterApiUserShow] = useState(null);
  const [isModalProfileApiUserShow, setIsModalProfileApiUserShow] = useState(false);
  const [isModalLoginApiCompanyShow, setIsModalLoginApiCompanyShow] = useState(null);
  const [isModalCompanyUsersListShow, setIsModalCompanyUsersListShow] = useState(false);
  const [isModalRegisterApiCompanyShow, setIsModalRegisterApiCompanyShow] = useState(false);
  const [apiUsersData, setApiUsersData] = useState(null);
  const [apiUsersError, setApiUsersError] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [isNeedUpdate, setIsNeedUpdate] = useState(true);
  const [systemInfo, systemInfoError] = useCryptoPro();
  const [profileData, setProfileData] = useState(null)

  const [abonentId, setAbonentId] = useState('');
  const [companies, setCompanies] = useState([]);

  const {Meta} = Card;

  useEffect(() => {
    if (isNeedUpdate) {
      epdAstralService.getInfoApiUser()
        .then(resp => {
          setApiUsersData(resp);
          setIsNeedUpdate(false);
        })
        .catch((err) => {
          setApiUsersError(err);
          notification.error({
            message: "Авторизация пользователя ЭДО ЭТрН", description: err,
          });
        });
    }
  }, [isNeedUpdate]);

  useEffect(() => {
    if (isNeedUpdate) {
      epdAstralService.getAvailableCompanies()
        .then((res) => {
          setCompanies(res);
        })
        .catch((err) => {
          notification.error({
            message: "Получение списка компаний",
            description: err,
          });
        });
    }
  }, [isNeedUpdate]);

  useEffect(() => {
    if (isNeedUpdate) {
      epdAstralService.getProfile()
        .then(resp => {
            setProfileData(resp);
            if (Object.entries(resp).find((item) => (item[1] === null))) {
              setIsModalProfileApiUserShow(true)
            }
          }
        )
        .catch((err) => {
          notification.error({
            message: "Получение данных профиля пользователя ЭДО ЭТрН", description: err,
          });
        });
    }
  }, [isNeedUpdate]);

  useEffect(() => {
    if (apiUsersData && apiUsersData.length > 0) {
      const activeUser = apiUsersData.find((user) => {
        return user.selected === 1 && user.companies.length === 1
      });
      if (activeUser && activeUser.companies[0].selected === 0) {
        selectCompany(activeUser.id, activeUser.companies[0].id)
      }
    }
  }, [apiUsersData]);

  const selectApiUser = (userId) => {
    epdAstralService.selectApiUser({etrnUserId: Number(userId)})
      .then(resp => {
        setIsNeedUpdate(true);
      })
      .catch((err) => {
        notification.error({
          message: "Выбор активного пользователя/активной компании", description: err,
        });
      });
  }

  const selectCompany = (userId, companyId) => {
    epdAstralService.selectApiUser({etrnUserId: Number(userId), etrnCompanyId: Number(companyId)})
      .then(resp => {
        notification.info({
          message: "Выбор активного пользователя/компании", description: resp.message,
        });
        setIsNeedUpdate(true);
      })
      .catch((err) => {
        notification.error({
          message: "Выбор активного пользователя/активной компании", description: err,
        });
      });
  }

  const registerApiUser = (username, password) => {
    epdAstralService.registerApiUser({username: username, password: password})
      .then(resp => {
        if (resp.status === 'success') {
          notification.success({
            message: "Логин в системе ЭДО ЭТрН",
            description: resp.message,
          });
          setIsModalRegisterApiUserShow(null);
          setIsNeedUpdate(true);
        } else if (resp.status === 'error') {
          setFieldsErrors(resp.errors);
          notification.error({
            message: "Логин в системе ЭДО ЭТрН",
            description: resp.message,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Логин в системе ЭДО ЭТрН",
          description: err,
        });
      });
  }

  const loginApiUser = (username, password) => {
    epdAstralService.loginApiUser({username, password})
      .then(resp => {
        if (resp.status === 'success') {
          notification.success({
            message: "Логин в системе ЭДО ЭТрН",
            description: resp.message,
          });
          setIsModalRegisterApiUserShow(null);
          setIsNeedUpdate(true);
        } else if (resp.status === 'error') {
          setFieldsErrors(resp.errors);
          notification.error({
            message: "Логин в системе ЭДО ЭТрН",
            description: resp.message,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Логин в системе ETRN", description: err,
        });
      });
  }

  const joinCompany = (abonentId) => {
    if (abonentId.length > 0) {
      epdAstralService.joinCompany({abonentId: abonentId})
        .then(resp => {
          if (resp.status === 'success') {
            notification.success({
              message: "Заявка на присоединение к компании",
              description: resp.message,
            });
            setIsNeedUpdate(true);
          } else if (resp.status === 'error') {
            notification.error({
              message: "Заявка на присоединение к компании",
              description: resp.message,
            });
          }
        })
        .catch((err) => {
          notification.error({
            message: "Заявка на присоединение к компании",
            description: err,
          });
        });
    }
  }

  return (<React.Fragment>
    {systemInfoError !== null && <Alert type="error" message={<div>
      <span>{systemInfoError}</span>
      <div><strong>Возможные причины:</strong>
        <ol>
          <li>Не установлен плагин:
            <a href="https://www.cryptopro.ru/products/cades/plugin/get_2_0" target="_blank"
               style={{fontWeight: "bold", color: "green", textDecoration: "underline"}}> Скачайте</a> его,
            перезапустите браузер и попробуйте снова
          </li>
          <li>У плагина нет доступа:
            Дайте доступ согласно <a href="https://docs.cryptopro.ru/cades/plugin/plugin-installation-windows"
                                     target="_blank" style={{
              fontWeight: "bold",
              color: "green",
              textDecoration: "underline"
            }}>инструкции</a></li>
        </ol>
      </div>
    </div>
    } banner/>}
    {apiUsersError !== null && <Alert type="error" message={<span>{apiUsersError}</span>} banner/>}
    <h1 className="content-header">Настройки ЭДО ЭТрН</h1>
    <Row gutter={0} style={{paddingRight: "0"}}>
      <Col span={24}>
        {apiUsersError &&
          <Button style={{marginLeft: "10px"}} type="primary" htmlType="button"
                  onClick={() => setIsModalRegisterApiUserShow(`login`)}>
            Авторизоваться
          </Button>
        }
        <ModalRegisterApiUser isShow={isModalRegisterApiUserShow === `register`}
                              onCloseModal={setIsModalRegisterApiUserShow}
                              fieldsErrors={fieldsErrors} onSubmit={registerApiUser}/>
        <ModalRegisterApiUser isShow={isModalRegisterApiUserShow === `login`}
                              onCloseModal={setIsModalRegisterApiUserShow}
                              fieldsErrors={fieldsErrors} onSubmit={loginApiUser}/>
      </Col>
    </Row>
    <Row gutter={[0, 48]} style={{paddingRight: "0"}}>
      {apiUsersData !== null && apiUsersData.map((user) => {
        const expired = moment(user.expired);
        const isNeedLogin = user.expired === null || expired.diff(moment.now()) < 0;
        const cardButtons = [];
        if (user.selected === 1) {
          if (userServices.getUser().isRole(['carrier_owner', 'carrier_dispatcher'])) {
            cardButtons.push(<>
              <Button
                type="primary"
                icon="plus"
                key={`${user}-plus`}
                onClick={() => setIsModalRegisterApiCompanyShow(true)}
              >
                Добавить компанию
              </Button>
              <ModalLoginApiCompany onCloseModal={setIsModalRegisterApiCompanyShow} type={'register'} userId={user.id}
                                    isShow={isModalRegisterApiCompanyShow} setIsNeedUpdate={setIsNeedUpdate}/>
            </>)
          }
          cardButtons.push(<>
            <Button
              type="primary"
              icon="edit"
              key={`${user}-edit`}
              onClick={() => {
                setIsModalProfileApiUserShow(true)
              }}
            >Редактировать профиль
            </Button>
          </>);
          if (user.selected === 1 && user.companies && user.companies.some(item => item.is_main)) {
            cardButtons.push(<>
              <Button
                type="primary"
                icon="team"
                key={`${user}-team`}
                onClick={() => setIsModalCompanyUsersListShow(true)}
              >Список пользователей
              </Button>
              <ModalCompanyUsersList onCloseModal={setIsModalCompanyUsersListShow}
                                     setIsNeedUpdate={setIsNeedUpdate} isShow={isModalCompanyUsersListShow}
              />
            </>)
          }
        }
        return <Col span={24} key={user.id}>
          <Card
            title={
              <Meta
                avatar={<Icon type="user"
                              style={{marginRight: "20px", color: `${user.selected === 1 ? 'green' : 'lightgray'}`}}/>}
                title={user.name}
                description={<span>{user.firstname} {user.patronymic} {user.surname} </span>}
              />}
            className={"astral-user-card"}
            style={{
              marginTop: 16,
              border: "2px solid #1CAA53",
              borderRadius: "7px",
              borderColor: `${user.selected === 1 ? "#1CAA53" : "lightgray"}`
            }}
            headStyle={{borderColor: `${user.selected === 1 ? '#1CAA53' : 'lightgray'}`}}
            extra={
              <>
                {isNeedLogin && <>
                  <Button type="primary" htmlType="button"
                          onClick={() => setIsModalRegisterApiUserShow(`login${user.id}`)}>Авторизоваться</Button>
                  <ModalRegisterApiUser isShow={isModalRegisterApiUserShow === `login${user.id}`}
                                        onCloseModal={setIsModalRegisterApiUserShow}
                                        fieldsErrors={fieldsErrors} onSubmit={loginApiUser}/>
                </>}
                {!isNeedLogin &&
                  <Switch checkedChildren="Активный" unCheckedChildren="Выбрать активным"
                          defaultChecked={user.selected === 1}
                          disabled={user.selected === 1}
                          checked={user.selected === 1}
                          onClick={() => selectApiUser(user.id)}/>
                }
              </>
            }
            actions={cardButtons}
          >
            <List
              itemLayout="horizontal"
              size="large"
              className="astral-user-companies-list"
              dataSource={user.companies}
              header={<div className="astral-user-companies-list__header"><span>Компании пользователя:</span></div>}
              locale={{emptyText: 'нет привязанных компаний'}}
              renderItem={item => {
                const buttons = [];
                const expiredCompany = moment(item.expired);
                if (!isNeedLogin && item.selected === 0 && item.status === USER_STATUSES.ACTIVE) {
                  buttons.push(<Button
                    type="link" style={{padding: "0"}}
                    onClick={() => selectCompany(user.id, item.id)}
                  >Выбрать</Button>)
                }
                let userIcon;
                let userIconColor;
                switch (item.status) {
                  default:
                  case USER_STATUSES.ACTIVE:
                    userIcon = "check-circle";
                    userIconColor = "green";
                    break;
                  case USER_STATUSES.WAITING:
                    userIcon = "clock-circle";
                    userIconColor = "grey";
                    break;
                  case USER_STATUSES.REJECTED:
                    userIcon = "exclamation-circle";
                    userIconColor = "red";
                    break;
                  case USER_STATUSES.DELETED:
                    userIcon = "close-circle";
                    userIconColor = "red";
                    break;
                }
                if (item.is_main && (item.expired === null || expiredCompany.diff(moment.now()) < 0)) {
                  buttons.push(<>
                      <Button
                        type="link" style={{padding: "0"}}
                        onClick={() => setIsModalLoginApiCompanyShow(`${user.id}-${item.id}`)}
                      >Авторизоваться</Button>
                      <ModalLoginApiCompany companyId={item.id} userId={user.id}
                                            onCloseModal={setIsModalLoginApiCompanyShow}
                                            isShow={isModalLoginApiCompanyShow === `${user.id}-${item.id}`}
                                            setIsNeedUpdate={setIsNeedUpdate}
                      />
                    </>
                  )
                }
                return (
                  <List.Item
                    actions={buttons}
                    style={{marginTop: "20px"}}
                  >
                    <List.Item.Meta
                      avatar={<Icon type={userIcon}
                                    style={{
                                      fontSize: '18px',
                                      lineHeight: '24px',
                                      verticalAlign: "bottom",
                                      color: userIconColor
                                    }}/>}
                      title={`ИНН: ${item.inn}, Название: ${item.name}`}
                      description={<>
                        <span>Статус пользователя: <strong>{USER_STATUSES_TITLE[item.status]}</strong></span>
                        {item.is_main && <Icon type="setting"
                                               style={{
                                                 width: "10px",
                                                 height: "10px",
                                                 color: "green",
                                                 marginLeft: "10px"
                                               }}/>}
                      </>}
                    />

                  </List.Item>
                )
              }}
            />
            {companies.length > 0 &&
              <List
                itemLayout="horizontal"
                size="large"
                className="astral-user-companies-list"
                dataSource={companies}
                header={<div className="astral-user-companies-list__header"><span>Доступные компании:</span></div>}
                locale={{emptyText: 'нет доступных компаний'}}
                renderItem={item => {
                  const buttons = [<>
                    <Button type="link"
                            icon={"plus"} style={{marginLeft: "auto"}}
                            onClick={() => joinCompany(item.abonent_id)}
                    >
                      <span>Присоединиться</span>
                    </Button>
                  </>];
                  return (
                    <List.Item
                      actions={buttons}
                      style={{marginTop: "20px"}}
                    >
                      <List.Item.Meta
                        title={`ИНН: ${item.inn}, Название: ${item.name}`}
                      />
                    </List.Item>
                  )
                }}
              />
            }
            {user.selected === 1 &&
              <ModalProfileApiUser profileData={profileData} onCloseModal={setIsModalProfileApiUserShow}
                                   isShow={isModalProfileApiUserShow} setIsNeedUpdate={setIsNeedUpdate}/>
            }
          </Card>
        </Col>
      })}
    </Row>
  </React.Fragment>)
};
